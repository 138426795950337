import * as React from "react";
import { StandardTemplate } from "../components/Templates";
import {
  Headline,
  HeadlineContainer,
  LoadingScreen,
  RedAnimation,
  Row,
  Section,
} from "../components/GlobalComponents";
import { w3cwebsocket as W3CWebSocket } from "websocket";
import {
  HydrosenseDetails,
  HydrosenseDeviceDetailDefault,
  HydrosenseDeviceDetailProps,
  HydrosenseOverview,
} from "../components/ProjectComponents";
import {
  dataEndpointConfig,
  DeviceProps,
  getDeviceData,
  getIndexByObjectImei,
  getOverviewImeiList,
  getUrlParams,
  maybeParseJson,
} from "../data";
import { AccountInfo, InteractionStatus } from "@azure/msal-browser";
import { useEffect, useState } from "react";
import { useAccount, useMsal } from "@azure/msal-react";

const client = new W3CWebSocket(`wss://nodered.vodafone.dev/ws/iot-data`);

const HydrosensePage = () => {
  const [view, setView] = useState<string>("overview");
  const [currentDevice, setCurrentDevice] = useState("");
  const [deviceDetail, setDeviceDetail] = useState<HydrosenseDeviceDetailProps>(
    HydrosenseDeviceDetailDefault
  );
  const { instance, accounts, inProgress } = useMsal();
  const account = useAccount(accounts[0] || {});
  const [overviewData, setOverviewData] = useState<DeviceProps[]>([]);
  const [loading, setLoading] = useState(true);

  /**
   * Websocket Connection for live data
   */
  useEffect(() => {
    client.onclose = () => {
      alert("Backend connection lost.");
    };
    client.onmessage = (message) => {
      const data = maybeParseJson(message.data);
      if (data.topic && data.topic === "iotData") {
        if (view === "detail" && data.imei.toString() === currentDevice) {
          updateDetail(data);
        }
        updateOverview(data);
      }
    };
  }, [view, overviewData, deviceDetail]);

  /**
   * Initial Data from the DB
   */
  useEffect(() => {
    if (account && inProgress === InteractionStatus.None) {
      setLoading(true);
      if (view === "detail" && currentDevice !== "") {
        console.log(currentDevice);
        const query =
          dataEndpointConfig.data.fields +
          `&imei=eq.${currentDevice}` +
          dataEndpointConfig.data.order;

        getDeviceData(instance, account, query).then((data) => {
          setDeviceDetail({
            data: data,
            latestData: data[0],
            name:
              overviewData[getIndexByObjectImei(overviewData, currentDevice)]
                .name ?? "",
          });
          setLoading(false);
        });
      } else {
        console.log(dataEndpointConfig.latestDeviceData.fields);
        console.log(dataEndpointConfig.latestDeviceData.order);
        const query =
          dataEndpointConfig.latestDeviceData.fields +
          `?type=eq.hydrosense` +
          dataEndpointConfig.latestDeviceData.order;

        getDeviceData(instance, account, query).then((data) => {
          const imeiList = getOverviewImeiList(data);
          setOverviewData(data);
          subscribeToNewData(account, imeiList);
          setLoading(false);
        });
      }
    }
  }, [account, inProgress, instance, currentDevice, view]);

  /**
   * Update on new received data
   */
  const updateOverview = (update: DeviceProps) => {
    const newOverviewData = [...overviewData];
    const deviceIndex = getIndexByObjectImei(
      newOverviewData,
      update.imei.toString()
    );
    if (deviceIndex === -1) {
      return;
    }

    newOverviewData[deviceIndex].data = update.data;
    newOverviewData[deviceIndex].timestamp = update.timestamp;
    setOverviewData(newOverviewData);
  };

  const updateDetail = (update: DeviceProps) => {
    const newDetailData = { ...deviceDetail };

    setDeviceDetail({
      data: [update, ...newDetailData.data],
      latestData: update,
      name:
        overviewData[getIndexByObjectImei(overviewData, currentDevice)].name ??
        "",
    });
  };

  /**
   * Subscribe to new Data
   */
  const subscribeToNewData = (account: AccountInfo, imeiList: string[]) => {
    client.send(
      JSON.stringify({
        uuid: account.localAccountId,
        imeis: imeiList,
        topic: "subscribe",
      })
    );
  };
  /**
   * Switch between views
   */
  const changeView = (id?: string) => {
    setView(id ? "detail" : "overview");
    if (id) {
      setCurrentDevice(id);
    }
  };

  React.useEffect(() => {
    const user_projects = sessionStorage.getItem("user_projects");
    const params = getUrlParams();
    // IMEI set? => Go to detail view
    if (params && params.id) {
      changeView(params.id);
    }
  }, []);

  return (
    <StandardTemplate>
      <Section>
        {view === "overview" && (
          <>
            <HeadlineContainer color={"monochrome500"}>
              <Headline text={"HydroSense"} styleAs={"h1"} as={"h1"} />
            </HeadlineContainer>
            <Row center={"xs"}>
              {overviewData &&
                overviewData.map((device: DeviceProps) => {
                  return (
                    <HydrosenseOverview
                      key={device.imei}
                      device={device}
                      changeView={() => changeView(device.imei)}
                    />
                  );
                })}
            </Row>
          </>
        )}{" "}
        {view === "detail" && (
          <HydrosenseDetails
            deviceDetail={deviceDetail}
            changeView={() => changeView()}
          />
        )}
      </Section>
      <LoadingScreen show={loading} animation={RedAnimation} />
    </StandardTemplate>
  );
};

export default HydrosensePage;
